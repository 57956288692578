.home {
  width:100%;
  min-height:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .key {
    display: inline-block;
    width:200px;
    text-align:right;
    padding:0 10px;
    color:#666;
  }
  .value {
    display: inline-block;
    width:200px;
    text-align:left;
  }
  .btn {
    max-width:300px;
    text-align:center;
    margin:30px 0;
  }
  td {
    padding:0 15px;
    &.score {
      text-align:right;
    }
  }
  tr.active {
    font-weight: bold;
  }
  .leaderboard {
    margin-top:50px;
  }
}
